import {useLocation, Link} from 'react-router-dom';
import HeaderBanner from "../../header-banner/header-banner"
import Header from "../../header/header"
import NavBar from '../../navbar/navbar';
 import Footer from "../../footer/footer"
import "./articulo.css"
const Articulo = state => {
  const location = useLocation ();

  if (location.state.def) {
    return(
      <>
      <HeaderBanner />
    <div className='header'>

    <div className='container'>

      <Header />
           
          


          
     
   
    </div>
          <NavBar />
      </div>
      <div className="container">

      <Link to={'/blog'} className="volver-btn"><i class="fa-solid fa-chevron-left  mt-3"></i>Volver</Link>
      </div>
      <h1 className="h1-articulo-blog">{location.state.titulo}</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img
            className='img-perros-articulo'
              src={require ('../../imagenes/' + location.state.imagen)}s
              alt={location.state.id}
            />
          </div>
          <div className="col-11 col-lg-7 texto-articulo">

          <p>{location.state.def}</p>
          <p>{location.state.como}</p>
          </div>

        </div>
      </div>
      <div className='row mt-5'>
<Footer/>
      </div>
    </>
    )
  }
  if (location.state.higiene) {
    return(
      <>
      <HeaderBanner />
    <div className='header'>

    <div className='container'>

      <Header />
           
          


          
     
   
    </div>
          <NavBar />
      </div>
      <div className="container">

      <Link to={'/blog'} className="volver-btn"><i class="fa-solid fa-chevron-left  mt-3"></i>Volver</Link>
      </div>
      <h1 className="h1-articulo-blog">{location.state.titulo}</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img
            className='img-perros-articulo'
              src={require ('../../imagenes/' + location.state.imagen)}s
              alt={location.state.id}
            />
          </div>
          <div className="col-11 col-lg-7 texto-articulo">

          <p>{location.state.alimentacion}</p>
          <p>{location.state.higiene}</p>
          </div>

        </div>
      </div>
      <div className='row mt-5'>
<Footer/>
      </div>
    </>
    )
  }
  if (location.state.visita) {
    return(
      <>
      <HeaderBanner />
    <div className='header'>

    <div className='container'>

      <Header />
           
    
   
    </div>
          <NavBar />
      </div>
      <div className="container">

      <Link to={'/blog'} className="volver-btn"><i class="fa-solid fa-chevron-left  mt-3"></i>Volver</Link>
      </div>
      <h1 className="h1-articulo-blog">{location.state.titulo}</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img className='img-perros-articulo'
              src={require ('../../imagenes/' + location.state.imagen)}s
              alt={location.state.id}
            />
          </div>
          <div className="col-11 col-lg-7 texto-articulo">

          <p>{location.state.alimentacion}</p>
          <p>{location.state.desp}</p>
          <p>{location.state.vac}</p>
          <p>{location.state.higiene}</p>
            <p>{location.state.visita}</p>
          </div>

        </div>
      </div>
      <div className='row mt-5'>
<Footer/>
      </div>
    </>
    )
  }
  if (location.state.kit) {
    return(
      <>
      <HeaderBanner />
    <div className='header'>

    <div className='container'>

      <Header />
           
          


          
     
   
    </div>
          <NavBar />
      </div>
      <div className="container">

      <Link to={'/blog'} className="volver-btn"><i class="fa-solid fa-chevron-left  mt-3"></i>Volver</Link>
      </div>
      <h1 className="h1-articulo-blog">{location.state.titulo}</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img className='img-perros-articulo'
              src={require ('../../imagenes/' + location.state.imagen)}s
              alt={location.state.id}
            />
          </div>
          <div className="col-11 col-lg-7 texto-articulo">

          <p>{location.state.alimentacion}</p>
          <p>{location.state.calidad1}</p>
          <p>{location.state.calidad}</p>
          <p>{location.state.agua}</p>
            <p>{location.state.kit}</p>
          </div>

        </div>
      </div>
      <div className='row mt-5'>
<Footer/>
      </div>
    </>
    )
  }
  return (
    <>
    <HeaderBanner />
  <div className='header'>

  <div className='container'>

    <Header />
         
        


        
   
 
  </div>
        <NavBar />
    </div>
      <div className="container">

      <Link to={'/blog'} className="volver-btn"><i class="fa-solid fa-chevron-left  mt-3"></i>Volver</Link>
      </div>
      <h1 className="h1-articulo-blog">{location.state.titulo}</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img
            className='img-perros-articulo'
              src={require ('../../imagenes/' + location.state.imagen)}s
              alt={location.state.id}
            />
          </div>
          <div className="col-11 col-lg-7 texto-articulo">

          <ul className="lista-articulos-blog">
            <li><span className="tiempo">Tiempo</span>: {location.state.tiempo}</li>
            <li><span className="a">Alimentación</span>: {location.state.alimentacion}</li>
            <li><span className="h">Habitat</span>: {location.state.habitat}</li>
            <li><span className="i">Ideal</span>: {location.state.ideal}</li>
      
          </ul>
          </div>

        </div>
      </div>
      <div className='row mt-5'>
<Footer/>
      </div>
    </>
  )
  
};

export default Articulo;
