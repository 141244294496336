import {useSelector} from 'react-redux';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';

import HeaderBanner from '../../../header-banner/header-banner';
import Header from '../../../header/header';
import './raciones.css';

import NavBar from '../../../navbar/navbar';
import Paginator from 'react-hooks-paginator';

import Footer from "../../../footer/footer"
import Categorias from "../../../otros-animales/categorias/categorias";
import AntiparasitariosE from './antiparasitario-externo/antiparasitarioexterno';

const FarmaciaAntiparasitarios = () => {
  const ruta = useLocation()

  const pageLimit = 20;

  const [offset, setOffset] = useState (0);
  const [currentPage, setCurrentPage] = useState (1);
  const [data, setData] = useState ([]);
  const [currentData, setCurrentData] = useState ([]);

  const getData =  () => {
  fetch("https://veterinaria-la-comercial-server.vercel.app/antiparasitariosexternos").then(res=>res.json()).then(d=>setData(d))

  };
  useEffect (() => {
    getData (); 
  }, []);
  useEffect (
    () => {
      setCurrentData (data.slice (offset, offset + pageLimit));
    },
    [offset, data]
    );
    
    const ordenar=()=>{
      data.sort((a,b)=>{
        if(a.nombre<b.nombre){
      return -1
    }
    if(a.nombre>b.nombre){
    return 1
  }
  return 0
})  

}
ordenar()
  return (
    <>
    <HeaderBanner />
  <div className='header'>

  <div className='container'>

    <Header />
         
        


        
   
 
  </div>
        <NavBar />
    </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 slide-left">

            <Link to={'/'} className="volver-btn">
              <i className="fa-solid fa-chevron-left mt-3" />Volver
            </Link>
            <Categorias pathname={ruta.pathname}/>
          </div>

          <div className="col-lg-10 container-articulos mb-5">
            <div className="row">
            <h3 className='titulo-seccion mt-4'>Farmacia - antiparasitarios externos</h3>
              {currentData.map (a => <AntiparasitariosE key={a.id} prop={a} />)}
            </div>

          </div>

          <Paginator
          
            totalRecords={data.length}
            pageLimit={pageLimit}
            pageNeighbours={1}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>

      </div>
      <div className="row">

        <Footer />

      </div>
    </>
  );
};

export default FarmaciaAntiparasitarios;
