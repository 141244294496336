import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    alimentosSecosPerros : [],
    alimentosRoedores:[],
    jaulasRoedores: [],
    alimentosAves:[],
    accesoriosAves:[],
    accesoriosPeces:[],
    alimentosReptiles:[],
    jaulasAves:[],
    alimentosPeces:[],
    bombasYfiltrosPeces:[],
    mantAguaPeces:[],
    snacksPerros:[],
    accesoriosRoedores:[],
    alimentosSecosGatos:[],
    alimentosHumedosGatos:[],
    alimentosHumedosPerros:[],
    arenasSanitariasGatos:[],
    camas:[],
    casillasPerros:[],
    rascadores:[],
    platos:[],
    higieneGatos:[],
    higienePerros:[],
    antiparasitariosExternos:[],
    antiparasitariosInternos:[],
    transportadorasPerros:[],
    transportadorasGatos:[],
    platosGatos:[],
}

export const productosSlice = createSlice({
    name:"productosSlice",
    initialState,
    reducers:{ 
        agregarAlimentosSecosPerros: (state, action) => {
            state.alimentosSecosPerros = action.payload;
        },
        agregarAlimentosRoedores: (state, action) => {
            state.alimentosRoedores = action.payload;
        },
        agregarJaulasRoedores: (state, action) => {
            state.jaulasRoedores = action.payload;
        },
        agregarAlimentosAves: (state, action) => {
            state.alimentosAves = action.payload;
        },
        agregarAccesoriosAves: (state, action) => {
            state.accesoriosAves = action.payload;
        },
        agregarAccesoriosPeces: (state, action) => {
            state.accesoriosPeces = action.payload;
        },
        agregarAlimentosDeReptiles: (state, action) => {
            state.alimentosReptiles = action.payload;
        },
        agregarJaulasAves: (state, action) => {
            state.jaulasAves = action.payload;
        },
        agregarAlimentosPeces: (state, action) => {
            state.alimentosPeces = action.payload;
        },
        agregarBombasYfiltrosPeces: (state, action) => {
            state.bombasYfiltrosPeces = action.payload;
        },
        agregarMantPeces: (state, action) => {
            state.mantAguaPeces = action.payload;
        },
        agregarSnacksPerros: (state, action) => {
            state.snacksPerros = action.payload;
        },
        agregarAccesoriosRoedores: (state, action) => {
            state.accesoriosRoedores = action.payload;
        },
        agregarASG: (state, action) => {
            state.alimentosSecosGatos = action.payload;
        },
        agregarAHG: (state, action) => {
            state.alimentosHumedosGatos = action.payload;
        },
        agregarAlimentosHP: (state, action) => {
            state.alimentosHumedosPerros = action.payload;
        },
        agregarArenasSanitariasGatos: (state, action) => {
            state.arenasSanitariasGatos = action.payload;
        },
        agregarCamas: (state, action) => {
            state.camas = action.payload;
        },
        agregarCasillasPerros: (state, action) => {
            state.casillasPerros = action.payload;
        },
        agregarRascadores: (state, action) => {
            state.rascadores = action.payload;
        },
        agregarPlatos: (state, action) => {
            state.platos = action.payload;
        },
        agregarHigieneG: (state, action) => {
            state.higieneGatos = action.payload;
        },
        agregarHigieneP: (state, action) => {
            state.higienePerros = action.payload;
        },
        agregarAntiparasitariosExternos: (state, action) => {
            state.antiparasitariosExternos = action.payload;
        },
        agregarAntiparasitariosInternos: (state, action) => {
            state.antiparasitariosInternos = action.payload;
        },
        agregarTransportadorasPerros: (state, action) => {
            state.transportadorasPerros = action.payload;
        },
        agregarTransportadorasGatos: (state, action) => {
            state.transportadorasGatos = action.payload;
        },
        agregrarPlatosGatos: (state, action) => {
            state.platosGatos = action.payload;
        },
    }
})

export const {agregarAlimentosSecosPerros, agregarAlimentosRoedores, agregarJaulasRoedores, agregarAlimentosAves, agregarAccesoriosAves, agregarAccesoriosPeces,
agregarAlimentosDeReptiles, agregarJaulasAves, agregarAlimentosPeces,agregarBombasYfiltrosPeces, agregarMantPeces, agregarSnacksPerros, agregarAccesoriosRoedores, agregarASG,
agregarAHG, agregarAlimentosHP, agregarArenasSanitariasGatos, agregarCamas, agregarCasillasPerros, agregarRascadores, agregarPlatos, agregarHigieneG,agregarHigieneP,
agregarAntiparasitariosExternos, agregarAntiparasitariosInternos, agregarTransportadorasPerros, agregarTransportadorasGatos,agregrarPlatosGatos } = productosSlice.actions;

export default productosSlice.reducer;