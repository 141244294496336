
import {Link} from 'react-router-dom';

import HeaderBanner from '../header-banner/header-banner';
import Header from '../header/header';
import NavBar from '../navbar/navbar';

import Footer from '../footer/footer';

const NoHay = () => {




  return (
    <>
    <HeaderBanner />
  <div className='header'>

  <div className='container'>

    <Header />
  </div>
        <NavBar />
    </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 slide-left">

            <Link to={'/'} className="volver-btn">
              <i className="fa-solid fa-chevron-left mt-3" />Volver
            </Link>
        
          </div>

          <div className="col-lg-10 container-articulos mb-5">
            <div className="row">
            <h1 style={{marginTop:"2em", marginBottom:"15em"}}>No hay productos aún</h1>
            
            </div>

          </div>

    
        </div>

      </div>
      <div className="row">

        <Footer />

      </div>
    </>
  );
};

export default NoHay;
