const categorias = [
  {
    id: 1,
    nombre: 'Perros',
    categorias: [
      '- Alimentos secos',
      '- Alimentos humedos',
      '- Snacks',
      '- Higiene',
      '- Cepillos y corta uñas',
      '- Accesorios',
      '- Camas',
      '- Casillas',
      '- Transportadoras',
      '- Paseos',
      '- Platos',
      '- Juguetes',
      '- Ropa',
    ],
    ruta: [
      '/productos-de-Perros/alimentosSecos',
      '/productos-de-Perros/alimentosHumedos',
      '/productos-de-Perros/snacks',
      '/productos-de-Perros/higiene',
      '/productos-de-Perros/cepillos',
      '/productos-de-Perros/accesorios',
      '/productos-de-Perros/camas',
      '/productos-de-Perros/casillas',
      '/productos-de-Perros/transportadoras',
      '/productos-de-Perros/paseos',
      '/productos-de-Perros/platos',
      '/productos-de-Perros/juguetes',
      '/productos-de-Perros/ropa',
    ],
  },
  {
    id: 2,
    nombre: 'Aves',
    categorias: ['- Alimentos', '- Jaulas', '- Accesorios'],
    ruta: [
      '/productos-de-Aves/alimentos',
      '/productos-de-Aves/jaulas',
      '/productos-de-Aves/accesorios',
    ],
  },
  {
    id: 3,
    nombre: 'Gatos',
    categorias: [
      '- Alimentos secos',
      '- Alimentos humedos',
      '- Arenas sanitarias',
      '- Higiene',
      '- Cepillos y corta uñas',
      '- Accesorios',
      '- Sanitarios',
      '- Camas',
      '- Transportadoras',
      '- Paseos',
      '- Platos',
      '- Juguetes',
      '- Rascadores',
    ],
    ruta: [
      '/productos-de-Gatos/alimentosSecos',
      '/productos-de-Gatos/alimentosHumedos',
      '/productos-de-Gatos/arenasSanitarias',
      '/productos-de-Gatos/higiene',
      '/productos-de-Gatos/cepillos',
      '/productos-de-Gatos/accesorios',
      '/productos-de-Gatos/sanitarios',
      '/productos-de-Gatos/camas',
      '/productos-de-Gatos/transportadoras',
      '/productos-de-Gatos/paseos',
      '/productos-de-Gatos/platos',
      '/productos-de-Gatos/juguetes',
      '/productos-de-Gatos/rascadores',
    ],
  },
  {
    id: 4,
    nombre: 'Peces',
    categorias: [
      '- Alimentos',
      '- Peceras',
      '- Mantenimiento del agua',
      '- Bombas y filtros',
      '- Calentadores y termometros',
      '- Accesorios',
    ],
    ruta: [
      '/productos-de-Peces/alimentos',
      '/productos-de-Peces/peceras',
      '/productos-de-Peces/mantenimientodelagua',
      '/productos-de-Peces/bombasyfiltros',
      '/productos-de-Peces/calentadoresytermometros',
      '/productos-de-Peces/accesorios',
    ],
  },
  {
    id: 5,
    nombre: 'Roedores',
    categorias: ['- Alimentos', '- Jaulas', '- Accesorios'],
    ruta: [
      '/productos-de-Roedores/alimentos',
      '/productos-de-Roedores/jaulas',
      '/productos-de-Roedores/accesorios',
    ],
  },
  {
    id: 6,
    nombre: 'Reptiles',
    categorias: ['- Alimentos', '- Accesorios'],
    ruta: [
      '/productos-de-Reptiles/alimentos',
      '/productos-de-Reptiles/accesorios',
    ],
  },
  {
    id: 7,
    nombre: 'Farmacia',
    categorias: ['- Antiparasitarios externos', '- Antiparasitarios internos'],
    ruta: [
      '/productos-de-Farmacia/AntiparasitariosExternos',
      '/productos-de-Farmacia/antiparasitariosInternos',
    ],
  },
];

module.exports = categorias;
