import {Link} from 'react-router-dom';



const JaulaAve = ({prop}) => {
   
  return (
    <div className="col-4 col-lg-2 container-producto mt-4">
        <Link className="link-nombre-producto" to={`/productos-de-Aves/jaulas/${prop.id}`} state={prop}>
      <div className="container-imagen-producto">
   
   

          <img
            className="img-producto-perros"
            src={`https://d20fcseidpdftl.cloudfront.net/aves/${prop.url}`}
            alt={prop.id}
          />
      <hr></hr>
      </div>
  

          {prop.nombre}
        </Link>
     
  
    </div>
  );
};

export default JaulaAve;