import {useState, useEffect} from 'react';

import {Link, useNavigate} from 'react-router-dom';
import {client} from '../../supabase/cliente';

import './header.css';


const Header = () => {

  const [search, setSearch] = useState ('');
  const navigate = useNavigate ();
  const [data, setData] = useState ([]);
  const [dataJ, setDataJuguetes] = useState ([]);

  const url = 'https://server-mongodb.vercel.app';
  const urlJuguetes = 'https://server-mongodb.vercel.app/productos-de-Gatos/juguetes';
   const getData = async () => {
    const res = await fetch (url);
    const resJuguetes = await fetch (urlJuguetes);
    const data = await res.json ();
    const dataJu = await resJuguetes.json ();
    setData (data);
    setDataJuguetes (dataJu);
    console.log (data);
  };
  useEffect (() => {
    getData ();
  }, [])

  const handleSearch = async e => {
    let productos = [];
    
    productos = data.filter (prod => {
     
      return prod.nombre.toLowerCase().startsWith (search.toLowerCase ())
    });
    console.log (productos);
    navigate ('/busqueda', {
      state: {
        b: productos,
      },
    });
    if (search.includes ('jaula')) {
      const ja = await client
        .from ('jaulas de aves')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
    if (search.includes ('peces')) {
      const ja = await client
        .from ('accesorios de peces')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
    if (search.includes ('aves')) {
      const ja = await client
        .from ('accesorios de aves')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
    if (search.includes ('roedores')) {
      const ja = await client
        .from ('alimentos de roedores')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
  };

  return (
    <div className="header">

      <div className="">
        <div className="row">
          <div className="col-4 col-lg-5 menu">

            <Link to="/">
              <img
                className="col-12 col-lg-5 img-logo"
                src={require ('../imagenes/Veterinaria La Comercial.png')}
                alt="logo"
              />
            </Link>
          </div>

          <div class="col-6 col-lg-5 buscar input-container" >
            <div className="container-search">
              <div className="search-bar">
                <input
                  value={search}
                  type="text"
                  placeholder="Buscar por marca"
                  onChange={e => {
                    setSearch (e.target.value);
                  }}
               
                />
                <button
                  type="button"
                  onClick={handleSearch}
                  className="button-search"
           
                >
                  <i  class="fa-solid fa-magnifying-glass" />
                </button>

              </div>

            </div>

          </div>

          <div className="col-2 col-lg-2 cart">

            <Link to={'/carrito'} className="">

            <i class="fa-solid fa-bag-shopping"></i>
            </Link>
            {/* <div className='notificacion-carrito'>
                 <span className='noti'>{itemsCarrito}</span> 
                </div> 
 */}
          </div>

        </div>
      </div>

    </div>
  );
};

export default Header;
