// eslint-disable-next-line no-unused-expressions
const servicios =  [

    {
       id: 1,
        link: "/servicios",
       url: "envios.png"
     },
     {
       id: 2,
       link: "/servicios",
       url: "pel.png"
     },
     {
       id: 3,
       link: "/servicios",
       url: "urge.png"
     },
     {
       id: 4,
       link: "/servicios",
       url: "cons.png"
     }
   ];
   module.exports = servicios