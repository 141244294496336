import {Link} from 'react-router-dom';

import './racion.css';

const RacionGatoHumedo = ({prop}) => {
   
  return (
    <div className="col-4 col-lg-2 container-producto mt-4">
        <Link className="link-nombre-producto" to={`/productos-de-Gatos/alimentosHumedos/${prop._id}`} state={prop}>
      <div className="container-imagen-producto">
  

          <img
            className="img-producto-perros"
            src={`https://d20fcseidpdftl.cloudfront.net/${prop.imagen}`}
            alt={prop.id}
          />
 <hr></hr>
      </div>


          {prop.nombre}
        </Link>
   
  
    </div>
  );
};

export default RacionGatoHumedo