import './header-banner.css';
import MenuBurger from "../menu/menu";
const HeaderBanner = () => {
  return (
    <div className="row container-header-banner">
      <MenuBurger  pageWrapId={"page-wrap"} outerContainerId={"App"} />
      <div className="topbar">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-lg-7 text-sm">
              <div className="site-info">
                <a href="/" className='title-envios'>
                  <span className="material-icons-outlined">
                  <i class="fa-solid fa-van-shuttle"></i>
                  </span>
                  Envíos a domicilio | 
                </a>
             
                <a href="/" className='title-llamadas'>
                  <span className="material-symbols-outlined">
                  <i class="fa-solid fa-phone"> </i>
                  </span> 095 187 673
                </a>
              </div>
            </div>
       
            <div className="col-sm-4 col-lg-4 text-right text-sm siteInfo mt-3">
             {/*  <div className="site-info"> */}
                <ul>
                  <li><a href="#promos" className='promo'>Promociones</a> </li>
                  <li><a href="/servicios" className='promo'>Servicios</a></li>
                </ul>
               {/*  <a href="#promos" id='promo'>Promociones</a> 

                <a href="/servicios" id='promo'>Servicios</a> */}
            
               
             {/*  </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*     <div class="top-bar">
        <div class="container">
          <div class="row align-items-center">

            <div class="col-lg-5 col-md-7 d-none d-lg-block">
              <div class="row">
                <div class="col-4">
                  <div class="top-bar-item">
                    <div class="top-bar-icon">

                      <img
                        src={require ('../imagenes/envios-header.png')}
                        alt="envios"
                      />
                    </div>
                    <div class="top-bar-text">

                      <p className="title-envios">Envíos a domicilio</p>

                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="top-bar-item">
                    <div class="top-bar-icon">
                      <img
                        src={require ('../imagenes/llamada-telefonica.png')}
                        alt="envios"
                      />
                    </div>
                    <div class="top-bar-text">
                      <p className="title-envios">095 187 673</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default HeaderBanner;
