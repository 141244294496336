import { configureStore } from "@reduxjs/toolkit";
import productosReducer from "../features/productosSlice";

import Reducer from "../features/Reducer";
export const store = configureStore({
    reducer:{
        productosPerro: productosReducer,
        productosCarrito: Reducer,
       
   
    }
}) 