import {useEffect, useState, lazy, Suspense} from 'react';

import {obtenerLinkPago} from '../../features/carritoSlice';
import {useDispatch, useSelector} from 'react-redux';
import {client} from "../../supabase/cliente"
import './form.css';
import Boton from '../boton';
import { toast } from 'react-hot-toast';

const Form = ({productos, precioTotal}) => {
  console.log(productos)
  const [costoTotal, setCostoTotal]= useState(precioTotal);
/*   const items = JSON.parse (localStorage.getItem ('carrito')); */
/*   const carrito = useSelector(state=> state.productosCarrito.carritoProducts) */
  const [codigo, setCodigo]= useState("PELUDOS");
/* 
  useEffect(()=>{
      calcularCostoTotal()
},[productos]) */

const [comprador,setComprador] = useState({
  nombreComprador: "",
  direccion:"",
  barrio:"",
  telefono:"",
  mascota:"",
  envio:"",
  codigoDescuento:"",
  montoTotal:Math.floor(costoTotal),
  metodoPago: "",
  items: productos
});


/* const calcularCostoTotal =()=>{ */
  /*   const filtrado = JSON.parse (localStorage.getItem ('carrito')); */
/* let costo = 0;
productos.forEach(e => {
  costo = costo+ e.precio 
}); */
/* costo = costo; */
/* setCostoTotal(costo)

setComprador({...comprador, montoTotal: costoTotal})

} */

const obtenerLinke = useSelector (state => state/* .productosCarrito.init_point */);
const obtenerLink = JSON.parse(localStorage.getItem ('init_point'));
const dispatch = useDispatch ();
const [texto, setTexto] = useState ('');

  const [descuento, setDescuento]= useState(false)
  const [efectivoOpost, setEfetivoOpost]= useState("efectivo o post");
   const [checked, setChecked]= useState(false);
   
  const [checkedEfectivoPost, setCheckedEfectivoPost]= useState(false);
  const [checkedTransferencia, setCheckedTranferencia]= useState(false);
  
  const selectEnvio = e => {
    let index = e.target.selectedIndex;
    setComprador({...comprador,envio: e.target.options[index].text})
    if (e.target.value == 1) {
      setTexto (
        'Barrio la Comercial - Porongos 2419 Lunes a sábado de 9 a 20 horas. Luego de realizar la compra nos comunicaremos con usted para coordinar el retiro. '
        );
    } else if (e.target.value == 2) {
      setTexto (
        'Realizamos envíos a todo Montevideo, con compras mayores a $2.000 el envío es gratuito, con compras menores, el costo del envío es $200 el cual se debe abonar en el momento de la entrega. También realizamos envíos al interior a través de encomiendas con costo a cargo del comprador. Luego de realizar la compra por la web nosotros nos comunicaremos con usted para coordinar el envío. '
      );
    }
  };
  

const handleTextPeludo =(e)=>{
  let index = e.target.selectedIndex;
  setComprador({...comprador,mascota: e.target.options[index].text})
}

    const inputCupon =()=>{

    setCostoTotal(comprador.montoTotal - (comprador.montoTotal *0.10))
    comprador.montoTotal = costoTotal

} 

const send = async (e) => {
  e.preventDefault();
  
  comprador.metodoPago = "Mercado Pago"
  comprador.montoTotal = comprador.montoTotal + (comprador.montoTotal * 0.10) 
  if(comprador.montoTotal < 2000 && comprador.envio =="Envío"){
    comprador.montoTotal = comprador.montoTotal +200 
  }
  if(comprador.codigoDescuento ==codigo){
    comprador.montoTotal = costoTotal
    
  } 

  let productos = []
  for(let i = 0;i<comprador.items.length;i++){
    productos.push({
      nombre: comprador.items[i].nombre,
      cantidad:comprador.items[i].cantidad,
      peso: comprador.items[i].peso,
      medidas:comprador.items[i].medidas,
      tamaño:comprador.items[i].tamaño
    })
  
  }
  console.log(productos)
  const {error, data} =await client.from("compras").insert({"nombre":comprador.nombreComprador,"direccion":comprador.direccion, "telefono": comprador.telefono,
  "monto":comprador.montoTotal,"productos":JSON.stringify(productos), "envio":comprador.envio, "barrio":comprador.barrio, "mascota":comprador.mascota,"metodoPago":comprador.metodoPago

})
  /* calcularDescuento() */
  fetch('https://veterinaria-la-comercial-server.vercel.app/api/pago', {
   method:"POST",
     headers: {
       'Content-Type': 'application/json',
       "Access-Control-Allow-Origin": "*"
     },
     body: JSON.stringify(comprador),
     
   })
   .then (res => res.json ())
 
   .then(d=> dispatch(obtenerLinkPago(d)))
  
   .then(info=> {    
      console.log(info.payload)
     localStorage.setItem ('init_point', JSON.stringify (info.payload.init_point))
     setChecked(true)
   
   
   })
   .catch (e => console.log (e)); 
   
 } 
   
  
 
    const showTransferencia=async (e)=>{
      e.preventDefault()
      setCheckedTranferencia(true)
     
/*       if(comprador.codigoDescuento == codigo&& comprador.envio =="Envío"){
        comprador.montoTotal = comprador.montoTotal - (comprador.montoTotal * 0.05) 
      } */
      if(comprador.montoTotal < 2000&& comprador.envio =="Envío"){
        comprador.montoTotal = comprador.montoTotal +200 
      }
      if(comprador.codigoDescuento ==codigo){
        comprador.montoTotal = costoTotal
      
      }
      comprador.metodoPago = "Transferencia"
      let productos = []
      for(let i = 0;i<comprador.items.length;i++){
        productos.push({
          nombre: comprador.items[i].nombre,
          cantidad:comprador.items[i].cantidad,
          peso: comprador.items[i].peso,
          medidas:comprador.items[i].medidas,
          tamaño:comprador.items[i].tamaño
        })
      
      }
      const {error, data} =await client.from("compras").insert({"nombre":comprador.nombreComprador,"direccion":comprador.direccion, "telefono": comprador.telefono,
      "monto":Math.floor(comprador.montoTotal),"productos":JSON.stringify(productos), "envio":comprador.envio, "barrio":comprador.barrio, "mascota":comprador.mascota,"metodoPago":comprador.metodoPago})
      if(data){
        toast.success("La compra se ha realizado correctamente")
        
      }
      else{
        toast.error("Un error ocurrio al registrar la compra, intentalo otra vez. Gracias")
      }
 
        }
        
      const ShowEfectivoPost=async ()=>{
        setCheckedEfectivoPost(true)
        
        comprador.metodoPago = efectivoOpost
       
        if(comprador.codigoDescuento ==codigo){
          comprador.montoTotal = costoTotal
          
        }
        if(comprador.montoTotal < 2000&& comprador.envio =="Envío"){
          comprador.montoTotal = comprador.montoTotal +200 
        }
        let productos = []
        for(let i = 0;i<comprador.items.length;i++){
          productos.push({
            nombre: comprador.items[i].nombre,
            cantidad:comprador.items[i].cantidad, 
           peso: comprador.items[i].peso,
            medidas:comprador.items[i].medidas,
            tamaño:comprador.items[i].tamaño
            
          })
        
        }
        const {error,data} =await client.from("compras").insert({"nombre":comprador.nombreComprador,"direccion":comprador.direccion, "telefono": comprador.telefono,
        "monto":comprador.montoTotal,"productos":JSON.stringify(productos), "envio":comprador.envio, "barrio":comprador.barrio, "mascota":comprador.mascota,"metodoPago":comprador.metodoPago})
          if(data){
            toast.success("La compra se ha realizado correctamente")
            
          }
          else{
            toast.error("Un error ocurrio al registrar la compra, intentalo otra vez. Gracias")
          }
          }
      

 

  return (
    <form className="form mb-5">
      <div className="form-group">

        <input
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Nombre y apellido"
          onChange={e=>setComprador({...comprador, nombreComprador: e.target.value})}
        />

      </div>

      <div className="form-group">

        <input
          type="text"
          className="form-control"
          id="exampleInputPassword1"
          placeholder="Dirección"
          onChange={e=>setComprador({...comprador, direccion: e.target.value})}
        />
      </div>
      <div className="form-group">

        <input
          type="text"
          className="form-control"
          id="exampleInputPassword1"
          placeholder="Teléfono"
          onChange={e=>setComprador({...comprador, telefono: e.target.value})}
        />
      </div>
      <div className="form-group">

        <input
          type="text"
          className="form-control"
          id="exampleInputPassword1"
          placeholder="Barrio"
          onChange={e=>setComprador({...comprador, barrio: e.target.value})}
        />
      </div>
      <select className="form-select" aria-label="Default select example" onChange={handleTextPeludo}>
        <option selected>¿Que peludito tienes?</option>
        <option value="1">Gato</option>
        <option value="2">Perro</option>
        <option value="3">Ambas</option>
        <option value="4">Otro</option>
      </select>
      <select
        className="form-select"
        aria-label="Default select example"
        onChange={selectEnvio}
      >
        <option selected>Elije la forma de retiro</option>
        <option value="1">Local</option>
        <option value="2">Envío</option>

      </select>
     
      <div className="form-group">

        <input
          type="text"
          className="form-control"
          id="exampleInputPassword1"
          placeholder="Codigo de descuento"
          onChange={(e)=>setComprador({...comprador,codigoDescuento:e.target.value})}
          />
          <small>Ingresa un codigo para tener un descuento especial en tu compra del <strong>10%</strong>.</small> 
          <button type='button' className='btn-cupon'  onClick={inputCupon}>Ingresar cupon</button>
          <p className='mt-3'>Las compras MAYORES a $2000 tienen envío gratis.</p>
            </div>
            <p className="mt-2">{texto}</p>
           
      <p><strong className='pago-total'>Total a pagar</strong>: ${costoTotal} </p>
            <div className='row container'>

            <button type="submit" className="btn-p"  onClick={send}>
              Pagar con Mercado Pago (comision del 10%, Tarjeta de debito/credito, abitab y red pagos) 
              <img src={require("../imagenes/mp.png")} className="logo-mp" alt="Mercado pago"/>
            </button> 
            </div>
            <div className='row container mt-4 '>
             {checked ? ( 
           
              
 
               <button type="submit" value={`${obtenerLink}`}  className="btn-pagar" >
              <a href={`${obtenerLink}`} className="btn-pagar" target="_blank" rel="noreferrer">PAGAR</a> 
               </button> 
           
          
           ) : ( 
            <div />
          )}
     
      </div>
      <div className='row container mt-4 '>
        <button type="button"  onClick={showTransferencia} className="btn-pagar">
        TRANSFERENCIA BANCARIA
          </button>
        <p>Aviso!! Al hacer click se registrara la compra en el sistema.</p>
          {checkedTransferencia ? (
           <p>Por TRANSFERENCIA BANCARIA a ITAU 9843075, Gabriela lorlano Bueno, mandar comprobante por Whatsapp para confirmar pedido.</p>
          
          ) : (
            <div />
          )}
     
      </div>
      <div className='row container mt-4 '>
        <button type="button"  onClick={ShowEfectivoPost} className="btn-pagar">
        EFECTIVO O POS
          </button>
          <p>Aviso!! Al hacer click se registrara la compra en el sistema.</p>
      {checkedEfectivoPost ? (
        <p>Se abona en el momento de la entrega o retiro.</p>
          
          ) : (
            <div />
          )}
     
      </div>


    </form>
  )

          }

export default Form;
