import './sliderdestacados.css';
import {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Link } from 'react-router-dom';
import { client } from '../../supabase/cliente';
import 'swiper/css';
import { Autoplay } from 'swiper';


const Carrousel = () => {
const [destacados, setDestacados] = useState([])

  useEffect (() => {
    
    const getDestacados=async()=>{
      const d = await client.from("destacados").select("*")
      setDestacados(d.data)
    }
    getDestacados()

  }); 
  const ordenar=()=>{
    destacados.sort((a,b)=>{
      if(a.nombre>b.nombre){
    return -1
  }
  if(a.nombre<b.nombre){
  return 1
}
return 0
})  

}
ordenar()
  return (
    <>
  
    <div className='row justify-content-center des'>
<h2 className='titulo-destacadoss'>Destacados</h2>
    </div>
   
        <div className='container'>
          <div className='row'>
    <Swiper
        slidesPerView={3}
  
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
         style={{width:"90%"}} 
       autoplay={true}
         modules={[Autoplay]}
        className="mySwiper"
      >
        {
          destacados.map((d,i)=>{
            console.log(d)
            return( 
              <SwiperSlide>
                 
                    <div key={i} className="producto-destacado">
                      <Link to={`/productos-de-Perros/alimentosSecos/${d.idDestacados}`}className='nombre-prod-dest link' style={{textDecoration:"none"}} state={d}>
                    <img  src={`https://veterinarialacomercial.s3.amazonaws.com/${d.imagen}`} alt={i} className="img-destacados" />
                      <hr className='linea-destacados'></hr>
                      {d.nombre}</Link>
                  
                    </div>
               
          </SwiperSlide>

)
})
}

   
      </Swiper>
</div>
</div>

    
        </>
  );
};

export default Carrousel;
