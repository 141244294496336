import './otro-animal.css';

import {Link} from 'react-router-dom';

const OtroAnimal = ({id, url, animal}) => {
  
  if(animal == "Perros" && url=="perrito.png"){
    return (
      <div className="otro-producto col-6 col-lg-3 pt-2" key={id}>
        <div className="">
          <Link to={`/productos-de-${animal}/alimentosSecos`} className="p.nombre">
          <img
            className="producto-img"
            src={require (`../imagenes/${url}`)}
            key={id}
            alt={id}
          />
           
          </Link>
  
        </div>
      </div>
    );

  }
  if(animal == "Gatos"&& url=="gatito.jpg"){
    return (
      <div className="otro-producto col-6 col-lg-3 pt-2" key={id}>
        <div className="">
          <Link to={`/productos-de-${animal}/alimentosHumedos`} className="p.nombre">
          <img
            className="producto-img"
            src={require (`../imagenes/${url}`)}
            key={id}
            alt={id}
          />
           
          </Link>
  
        </div>
      </div>
    );

  }
  if(animal == "Peces"&& url=="pecesito.png"){
    return (
      <div className="otro-producto col-6 col-lg-3 pt-2" key={id}>
        <div className="">
          <Link to={`/productos-de-${animal}/alimentos`} className="p.nombre">
          <img
            className="producto-img"
            src={require (`../imagenes/${url}`)}
            key={id}
            alt={id}
          />
           
          </Link>
  
        </div>
      </div>
    );

  }
};

export default OtroAnimal;
