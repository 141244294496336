import {useEffect, useState} from 'react';
import {agregarCantidad, agregarProductosAlCarrito} from '../../../../../features/carritoSlice';
import Header from '../../../../header/header';
import HeaderBanner from '../../../../header-banner/header-banner';
import NavBar from '../../../../navbar/navbar';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useParams} from 'react-router-dom';
import './detalle.css';
import {Toaster, toast} from 'react-hot-toast';
import Footer from '../../../../footer/footer';


const VistaDetalleAntiparsitariosExternos = () => {
  const {state} = useLocation ();

  const dispatch = useDispatch ();
  const dispatchCantidad = useDispatch ();
  const [cantidad, setCantidad] = useState (1);
  const [precioSeleccionado, setPrecioSeleccionado] = useState (
    state.precio
  );

/*   const opcionSeleccionada = precio => {
    setPrecioSeleccionado (precio);
  }; */

  const sumarCantidad = () => {
    setCantidad (cantidad + 1);

  };
  const restarCantidad = () => {
    setCantidad (cantidad - 1);
    if (cantidad <= 1) {
      setCantidad (1);
      toast.error ('No puedes elegir una cantidad negativa o menor a 1');
    }
  };

 

  if (state ) {
    const agregarAlCarrito = (cantidad, precio) => {
      let producto = {
        id:state.id,
        nombre :  state.nombre,
        foto: state.imagen,
       descripcion: state.descripcion,
      precio: precioSeleccionado * cantidad,
      cantidad: cantidad,
      marca: state.marca
      }
      
      dispatch({ type: "ADD", payload: producto })
  
     
   /*    dispatch (agregarProductosAlCarrito (producto));
      dispatchCantidad(agregarCantidad())
   */
      toast.success ('Agregado exitosamente');
    };
    return (
      <>
      <HeaderBanner />
    <div className='header'>
  
    <div className='container'>
  
      <Header />
          
    </div>
          <NavBar />
      </div>
  
        <div className="col-lg-12">

          <div className="container">

            <div className="row d-flex">
              <div className="col-lg-12">
                <Link to={'/productos-de-Farmacia/antiparasitariosExternos'} className="volver-btn">
                  <i class="fa-solid fa-chevron-left  mt-3" />Volver{' '}
                </Link>

              </div>

              <div className="col-lg-5">

                <img
                  className="img-producto-detallee"
                  src={`https://d20fcseidpdftl.cloudfront.net/farmacia/${state.imagen}`}
                  alt={state.id}
                />
              </div>

              <div className="col-lg-7 ">

                <div className="col-lg-12">

                  <h3 className="titulo-producto-detalle">
                    {state.nombre}
                  </h3>
                  <div className="col-lg-10 descripcion-producto-detalle">

                    <p>{state.descripcion}</p>
                 {/* mayuscula y negrita Y EN RACIONES NOOOOOO  */}
        
                  </div>
                  <div className='row'>
                  

                  <div className="col-10 col-lg-8 consulta-mensaje">
                    <div className='col-12 col-lg-12'>
                    <p>CONSULTAR STOCK POR WHATSAPP ANTES DE COMPRAR</p>

                    </div>
                    <div className='col-lg-12'>

                    <i className="fa-brands fa-whatsapp"></i>
                    </div>
                  </div>
                  </div>
                 
                </div>

               

                <div className="row botones-cantidades-prod mt-5">
                  <button onClick={restarCantidad} className="col-2 col-lg-1  btn-mas">
                    -
                  </button>
                  <span className="col-2 col-lg-1">
                    <p className="col-2 text-center cantidad-prod">{cantidad}</p>

                  </span>
                  <button onClick={sumarCantidad} className="col-2 col-lg-1 btn-mas ">
                    +
                  </button>
                </div>

                <div className="mt-3">

                  <h4 className="precio">${precioSeleccionado * cantidad}</h4>
                </div>
                <div>
                 
                  <button
                    className="btn-agregar-carrito mt-5"
                    onClick={() => agregarAlCarrito (cantidad, precioSeleccionado)}
                  >
                    Agregar al carrito

                  </button>
                </div>
              </div>

            </div>

          </div>
          <Toaster />
          <div className="row">
  
            <Footer />

          </div>
        </div>
      </>
    );
  
  } 
};

export default VistaDetalleAntiparsitariosExternos;
