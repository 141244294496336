import { FloatingWhatsApp } from "react-floating-whatsapp"
import logo from "../imagenes/logo.jpg"
import "./firma.css"

const Firma = () => {
  return (
    <div className="banner justify-content-center">
     <p className='col-12'> <i class="fa-regular fa-copyright cd" />

      2022 Veterinaria La Comercial | Todos los derechos reservados. Desarrollado por Dominium: dominium.vercel.app</p>
      <FloatingWhatsApp
        accountName="Veterinaria La Comercial"

        statusMessage="Hola"
        phoneNumber="+59895187673"
        chatMessage="Consulta por cualquier producto antes de comprar para verificar que tengamos stock"
        placeholder="Escribeme"
        allowClickAway
        avatar={logo} 
      
      />
    </div>
  )
}

export default Firma