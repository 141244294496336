import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux/es/exports';

import Footer from '../footer/footer';
import Form from '../checkout-form/form';
import {toast, Toaster} from 'react-hot-toast';
import {Link} from 'react-router-dom';
import HeaderBanner from '../header-banner/header-banner';
import Header from '../header/header';
import NavBar from '../navbar/navbar';
import './carrito.css';
const Carrito = () => {

const [precioTotal, setPrecioTotal] = useState(0)
  const dispatch = useDispatch ();
  const productos = useSelector (state => state.productosCarrito);
  console.log(productos)

  const vaciarTodoElCarrito = () => {
    
    
    localStorage.removeItem ('carrito');
    
    toast.success ('Carrito vaciado');
    dispatch ({ type: "CLEAR"});
  };

  const eliminarDeCarrito = (p) => {
    
    

    toast.success ('Eliminado exitosamente');
    dispatch ({ type: "REMOVE", payload: p });
  };
 
  if (productos.length>=1) { 



 let costo = 0;
productos.forEach(e => {
  costo = costo+ e.precio 
}); 

    return (
      <>
      <div>
        <HeaderBanner />
        <Header />
        <div className="container-fluid">

          <div className="row">
            <NavBar />

          </div>
        </div>
        
          <div className="container mt-3">
            

          <Link to={'/'} className="volver-btn">
              <i class="fa-solid fa-chevron-left mt-3" />Volver
            </Link>
     
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
           <button className='btn-vaciar'onClick={()=>vaciarTodoElCarrito() } title="Vaciar carrito">Vaciar carrito</button>

                  <div className="product-container">
                  <table className='tabla-productos'>                       
                      <thead>      
                        <div className='row'>
                          <div className='col-5 col-lg-6'>

                          <th>Producto</th>
                          </div>
                          <div className='col-3 col-lg-3'>

                          <th>Cantidad</th>
                          </div>
                          <div className='col-2 col-lg-2'>

                          <th>Precio</th>
                          </div>
                          {/* <div className='col-2 col-lg-1'>

                          <th>Eliminar</th>
                          </div> */}
                          </div>             
                      </thead>
                  
                      
                    
                      <tbody>
                    {productos.map (p => (
                      <>
                        <tr>
                          <div className='row'>
                        <div className="col-3 col-lg-2">
                          {
                            p.marca=="transportadoras"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/transportadoras/${p.foto}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="asp"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/${p.foto}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="snacks"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/snacksPerros/${p.url}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="asg"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="ahp"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="ahg"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="cepillosycortauñas"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/cepillosycortauñas/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="higiene"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/higiene/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
              
                          {
                            p.marca=="camas"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/camas/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="casillas"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/casillas/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="paseos"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/paseos/${p.foto}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="platos"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/platos/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="arenas"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/arenassanitarias/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="rascadores"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/rascadores/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="alimentoA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/aves/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="jaulasA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/aves/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="accesoriosA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/aves/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="alimentosA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/aves/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="jaulasA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/aves/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="accesoriosA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/aves/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="alimentosP"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/peces/${p.url}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="mantenimiento"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/peces/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="bombas"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/peces/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="accesoriosP"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/peces/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="alimentosR"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/roedores/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="jaulasR"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/roedores/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="AccesoriosR"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/roedores/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="reptilesA"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/roedores/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="ai"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/farmacia/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                          {
                            p.marca=="ae"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/farmacia/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                                 {
                            p.marca=="sanitarios"?(
                              <img
                                className="img"
                                src={`https://veterinarialacomercial.s3.amazonaws.com/sanitarios/${p.imagen}`}
                                alt={p.id}
                                />

                            ):(
                              <></>
                            )
                          }
                        </div>
                            <div className='col-2 col-lg-5'>

                            <h4 className='mt-5 nombre-producto'>{p.nombre}</h4>
                          
                       
                            </div>
                            <div className='col-3 col-lg-2'>

                            <p className='mt-5 text-center'>{p.cantidad}</p>
                          
                       
                            </div>
                            <div className='col-2 col-lg-2'>

                            <p className='mt-5 text-center'>${p.precio}</p>
                          
                       
                            </div>
                            <div className='col-1 col-lg-1 mt-5'>

                            <i className="fa-solid fa-xmark"onClick={() => eliminarDeCarrito (p)}></i>
                          
                       
                            </div>
                         
                         
                     
                        </div>
                      </tr>
                      
                      <hr /> 
                      </>
                  
                  ))}
                  </tbody>
                      
                 
                  </table>
                  </div>

              
                      </div>
              

                <div className="col-lg-6">

                  <div className="product-container">

            
                    <Form  productos={productos} precioTotal={costo} setPrecioTotal={setPrecioTotal}/>
                  </div>
                </div>
                </div>

              </div>
            </div>
            <Toaster />
          </div>
 
        <div className="row">
         

         <Footer />
    

       </div>
      </>
    
    );
   } 
  
 
     if (productos.length<1) {

    return (
      <div>
        <HeaderBanner />
        <Header />
        <div className="container-fluid">

          <div className="row">
            <NavBar />

          </div>
        </div>
        <main>
          <div className="container mt-3">
            

          <Link to={'/'} className="volver-btn">
              <i class="fa-solid fa-chevron-left mt-3" />Volver
            </Link>

         
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
            <button className='btn-vaciar'onClick={vaciarTodoElCarrito } title="Vaciar carrito">Vaciar carrito</button>
               
                  <div className="product-container">
                    <h3 className='aviso-carrito'>No tienes productos en el carrito aún.</h3>
               
                  </div>
                </div>

                <div className="col-lg-6">

                  <div className="product-container">

                
                    {/* <Form />  */}
                  </div>
                </div>

              </div>
            </div>
            <Toaster />
          </div>
        </main>
        <div className="row carrito-footer">
         

          <Footer />
     

        </div>
      </div>
    );
  } 

};

export default Carrito;
