import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  carritoProducts: [],
  listDeProductosConComprador: [],
  init_point: "",
  /* cantidad:0, */
};

export const carritoSlice = createSlice ({
  name: 'carritoSlice',
  initialState,
  reducers: {
    agregarProductosAlCarrito: (state, action) => {
      console.log(action.payload)
      let item={
        id : action.payload.id,
        nombre: action.payload.nombre,
        imagen: action.payload.foto,
        descrip: action.payload.descripcion,
        cantidad: action.payload.cantidad,
        precio: action.payload.precio  * action.payload.cantidad,
        marca: action.payload.marca
      }

      localStorage.setItem("carrito", JSON.stringify(state.carritoProducts))
      state.carritoProducts.push (item);
 
    },
    
    eliminarProductoDelCarrito: (state, action) => {

    return state.carritoProducts.filter (p =>p.id !== action.payload);
      
    },
    vaciarCarrito: (state, action) => {

     /*  const largo = state.carritoProducts.length -2 */
    
     return state.carritoProducts = []
    },
    obtenerLinkPago: (state, action) => {


      state.init_point = action.payload.init_point;
    },
    agregarCantidad: (state, action) => {

        
      state.cantidad++;   
 
    },
    agregarDatosComprador: (state, action) => {

      let compra={
        id : action.payload.id,
        nombre: action.payload.nombre,
        imagen: action.payload.foto,
        descrip: action.payload.descripcion,
        cantidad: action.payload.cantidad,
        precio: action.payload.precio  * action.payload.cantidad, 
        nombreComprador: action.payload.nombre,
        barrio:action.payload.barrio ,
        direccion:action.payload.direccion,
        telefono:action.payload.telefono,
        mascota:action.payload.mascota
      }

      state.listDeProductosConComprador.push(compra);
    }
  },
});

export const {
  agregarProductosAlCarrito,
  eliminarProductoDelCarrito,
  vaciarCarrito,
  obtenerLinkPago,
  agregarDatosComprador,
  agregarCantidad
} = carritoSlice.actions;

export default carritoSlice.reducer;
