import {Link} from 'react-router-dom';
import './navbar.css';

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-danger">
      <div className="container">

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Perros
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Perros/alimentosSecos">Alimentos secos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/alimentosHumedos">Alimentos Humedos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/snacks">Snacks</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/higiene">Higiene</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/cepillos">Cepillos, corta uñas</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/accesorios">Accesorios</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/camas">Camas</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/casillas">Casillas</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/transportadoras">Transportadoras</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/paseos">Paseos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/platos">Platos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/juguetes">Juguetes</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Perros/ropa">Ropa</Link></li>
            
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Gatos
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Gatos/alimentosSecos">Alimentos secos</Link></li>
                <hr/>
                  <li><Link className='Link' to="/productos-de-Gatos/alimentosHumedos">Alimentos Humedos</Link></li>
                <hr/>
                  <li><Link className='Link' to="/productos-de-Gatos/arenasSanitarias">Arenas sanitarias</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/higiene">Higiene</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/cepillos">Cepillos y corta uñas</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/accesorios">Accesorios</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/sanitarios">Sanitarios</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/camas">Camas</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/transportadoras">Transportadoras</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/paseos">Paseos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/platos">Platos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/juguetes">Juguetes</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Gatos/rascadores">Rascadores</Link></li>
  
                

              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Aves
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Aves/alimentos">Alimentos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Aves/jaulas">Jaulas</Link></li>   <hr/>
                <li><Link className='Link' to="/productos-de-Aves/accesorios">Accesorios</Link></li>

              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Peces
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Peces/alimentos">Alimentos</Link></li>   <hr/>

                <li><Link className='Link' to="/productos-de-Peces/peceras">Peceras</Link></li>   <hr/>
                <li>
                  <Link className='Link' to="/productos-de-Peces/mantenimientodelagua">Mantenimiento del agua</Link>
                </li>   <hr/>
                <li><Link className='Link' to="/productos-de-Peces/bombasyfiltros">Bombas y filtros</Link></li><hr/>
                <li>
                  <Link className='Link' to="/productos-de-Peces/calentadoresytermometros">
                    Calentadores y termometros
                  </Link>
                </li>   <hr/>
                <li><Link className='Link' to="/productos-de-Peces/accesorios">Accesorios</Link></li>   <hr/>

              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Roedores
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Roedores/alimentos">Alimentos</Link></li>   <hr/>
                <li><Link className='Link' to="/productos-de-Roedores/jaulas">Jaulas</Link></li><hr/>
                <li><Link className='Link' to="/productos-de-Roedores/accesorios">Accesorios</Link></li>

              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Reptiles
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Reptiles/alimentos">Alimentos</Link></li><hr/>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                style={{color:"white"}}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Farmacia
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className='Link' to="/productos-de-Farmacia/antiparasitariosExternos">Antiparasitarios externos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Farmacia/antiparasitariosInternos">Antiparasitarios internos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Farmacia/suplementos">Suplementos</Link></li>
                <hr/>
                <li><Link className='Link' to="/productos-de-Farmacia/medicamentos">Medicamentos</Link></li>
           
    

              </ul>
            </li>
            <li className="nav-item dropdown">
       
                <Link to={"/servicios"} style={{color:"white"}} className="nav-link">Servicios</Link>
              
            
            </li>
   
            <li className="nav-item dropdown">
       
                <Link to={"/vista-promociones"} style={{color:"white"}} className="nav-link">Promociones</Link>
            </li>

          </ul>

        </div>
      </div>
    </nav>
  );
};

export default NavBar;
