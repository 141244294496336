import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import "swiper/css/autoplay";

import './promos.css';

// import required modules
import {Autoplay} from "swiper";
const Promos = () => {
  return (
    <div className='row promo mt-5' >
      <Swiper  modules={[Autoplay]}autoplay={true} className="mySwiper promos">
        <SwiperSlide><img src={require("../imagenes/promonueva2.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/promonueva3.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/promonueva1.png")} alt="marcas"/></SwiperSlide>
 

      </Swiper>
  </div>
  );
};

export default Promos;
