import data from '../../data-productos/data2';
import OtroAnimal from './otro-animal';

const OtrosAnimales = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">

        {data.map (p => <OtroAnimal key={p.id} {...p} />)}

      </div>

    </div>
  );
};

export default OtrosAnimales;
