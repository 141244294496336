// eslint-disable-next-line no-unused-expressions
const  data =  [

   {
      id: 1,
      animal: 'Perros',
      url: "perrito.png"
    },
    {
      id: 2,
      animal: 'Gatos',
      url: "gatito.jpg"
    },
    {
      id: 3,
      animal: 'Peces',
      url: "pecesito.png"
    },
 
  ];
  module.exports = data