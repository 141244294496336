import './blog.css';
import {Link} from 'react-router-dom';
const Blog = () => {
  return (
    <div className="container">
  
      <div className="row blog justify-content-center ">

        <div className="col-5 col-lg-5">
          <img
            className="blog-img-info"
            src={require ('../imagenes/blogportada.png')}
            alt=""
          />

        </div>
        <div className="col-7 col-lg-5">

          <h3 className="titulo-blog">¡Nuestro blog!</h3>
          <p className="texto-blog col-10">
            Para saber toda la infomación y consejos sobre los animalitos.
          </p>

          <Link to="/blog"><img  className='btn-blog' src={require("../imagenes/btnBlog.png")} alt="boton blog"/></Link>

        </div>
      </div>
    </div>
  );
};

export default Blog;
