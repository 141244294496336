import {Link} from 'react-router-dom';


const Plato = ({prop}) => {
  return (
    <div className="col-4 col-lg-2 container-producto mt-4">
      <Link className="link-nombre-producto" to={`/productos-de-Perros/platos/${prop.id}`} state={prop}>
      <div className="container-imagen-producto">
   
    

          <img
            className="img-producto-perro"
            src={`https://d20fcseidpdftl.cloudfront.net/platos/${prop.imagen}`}
            alt={prop.id}
          />
     <hr></hr>
      </div>
 

          {prop.nombre}
        </Link>
   
  
    </div>
  );
};

export default Plato;
