
import {Link} from 'react-router-dom';
import categorias from '../../../data-productos/categorias';
import "./categorias.css"
const Categorias = ({pathname}) => {


 
  return (
    <div className="col-lg-12 container-articulos-categorias">

      {categorias.map (c => {
        for (let i = 0; i < categorias.length; i++) {
          if (c.ruta[i] === pathname) {
            return (
              <ul className='lista-c'>
                <li>
                  <Link  className="link-c" to={`${c.ruta[0]}`}>
                     {c.categorias[0]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[1]}`}>
                    {c.categorias[1]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[2]}`}>
                    {c.categorias[2]}
                  </Link>
                </li>
        

                 <li>
                  <Link className="link-c" to={`${c.ruta[3]}`}>
                    {c.categorias[3]}
                  </Link>
                </li>
                
                
                <li>
                  <Link className="link-c" to={`${c.ruta[4]}`}>
                    {c.categorias[4]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[5]}`}>
                    {c.categorias[5]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[6]}`}>
                    {c.categorias[6]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[7]}`}>
                    {c.categorias[7]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[8]}`}>
                    {c.categorias[8]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[9]}`}>
                    {c.categorias[9]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[10]}`}>
                    {c.categorias[10]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[11]}`}>
                    {c.categorias[11]}
                  </Link>
                </li>
                <li>
                  <Link className="link-c" to={`${c.ruta[12]}`}>
                    {c.categorias[12]}
                  </Link>
                </li>
              </ul>
            );
          }
        }
      })}
    </div>
  );
};

export default Categorias;
