import {Link} from 'react-router-dom';


const Snack = ({prop}) => {
  return (
    <div className="col-4 col-lg-2 container-producto mt-4">
      <div className="container-imagen-producto">
  

          <img
            className="img-producto-perros"
            src={`https://d20fcseidpdftl.cloudfront.net/snacksPerros/${prop.imagen}`}
            alt={prop.id}
          />
   <hr></hr>
      </div>
   

        <Link className="link-nombre-producto" to={`/productos-de-Perros/snacks/${prop._id}`} state={prop}>
          {prop.nombre}
        </Link>

  
    </div>
  );
};

export default Snack;
