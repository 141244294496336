import {Link} from 'react-router-dom';



const Mant = ({prop}) => {
   
  return (
    <div className="col-4 col-lg-2 container-producto mt-4">
        <Link className="link-nombre-prod" to={`/productos-de-Peces/mantenimientodelagua/${prop.id}`} state={prop}>
      <div className="container-imagen-producto">
   
  

          <img
            className="img-producto-perros"
            src={`https://d20fcseidpdftl.cloudfront.net/peces/${prop.url}`}
            alt={prop.id}
          />
      <hr></hr>
      </div>
  

          {prop.nombre}
        </Link>
  
  
    </div>
  );
};

export default Mant