import Firma from '../firma/firma';

import './footer.css';

const Footer = () => {
  return (
    <div className="container footer">
      <footer className="row justify-content-center ">

        <div className="col-10 col-lg-4">
          <h3>Información</h3>
          <p>Dirección: Porongos 2419, Montevideo, Uruguay</p>
          <p>Teléfono: 095 187 673</p>
          <p>Email: vetlacomercial@hotmail.com</p>
        </div>
        <div className="col-10 col-lg-3">
          <h3>Metodos de pago</h3>
         {/*  <i class="fa-brands fa-cc-visa logo-i"></i>
          <i class="fa-brands fa-cc-mastercard logo-m"></i> */}
          <i class="fa-solid fa-building-columns logo-i"></i>
          <p> - Mercado Pago</p>
          <p> - Transferencia bancaria</p>
          <p> - Efectivo</p>
          <p> - Pos en el momento</p>
{/*           <p> - Otras tarjetas de credito y debito</p> */}
        </div>
        <div className="col-10 col-lg-3">
          <h3>Seguínos en las redes</h3>
          <a href='https://www.instagram.com/vet.la.comercial' target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram logo-ig"></i></a>
          <a href='https://www.facebook.com/VeterinariaLaComercial?mibextid=LQQJ4d' target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook logo-fb"></i></a>
        </div>
      
     
       
      </footer>
      <Firma/>
    </div>
  );
};

export default Footer;
