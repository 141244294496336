import './promociones.css';
import {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import { Autoplay, Pagination} from 'swiper';


const Promociones = () => {
const [destacados, setDestacados] = useState([])

  useEffect (() => {
    
    const getDestacados=async()=>{
      const d = await fetch("https://server-mongodb.vercel.app/")
      const data =await  d.json()
      setDestacados(data.filter(p=>p.promocion==="SI"))
      console.log(destacados)
    }
    getDestacados()

  }); 
  const ordenar=()=>{
    destacados.sort((a,b)=>{
      if(a.nombre>b.nombre){
    return -1
  }
  if(a.nombre<b.nombre){
  return 1
}
return 0
})  

}
ordenar()
  return (
    <>
  
   <div className='row justify-content-center'>
<h2 className='titulo-promociones'>Promociones</h2>
    </div>
   
        <div className='container q'>
          <div className='row'>
    <Swiper
           slidesPerView={3}
           spaceBetween={5}
           pagination={{
             clickable: true,
           }}
           modules={[Pagination, Autoplay]}

         style={{width:"90%"}} 
        className="mySwiper p"
      >
        {
          destacados.map((d,i)=>{
            console.log(d)
            return( 
              <SwiperSlide>
                 <div className='rectangulo'>

                    <div key={i} className="producto-promo">
                    <img  src={`https://veterinarialacomercial.s3.amazonaws.com/${d.imagen}`} alt={i} className="img-promos" />
                 </div>
                      <hr className='linea-promociones'></hr>
                      <div className='cajaTitulo'>

                      <Link to={`/vista-promociones/${d._id}`}className='nombre-prod-promo link' style={{textDecoration:"none"}} state={d}>
                      {d.nombre}</Link>
                      </div>
                  
                    </div>
               
          </SwiperSlide>

)
})
}

   
      </Swiper>
</div>
</div> 

    
        </>
  );
};

export default Promociones;
