import {useEffect, useState} from 'react';
import {agregarCantidad, agregarProductosAlCarrito} from '../../../../../features/carritoSlice';
import Header from '../../../../header/header';
import HeaderBanner from '../../../../header-banner/header-banner';
import NavBar from '../../../../navbar/navbar';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useParams} from 'react-router-dom';
import './detalle.css';
import {Toaster, toast} from 'react-hot-toast';
import Footer from '../../../../footer/footer';

const VistaDetalleDelProductoDeGatoPaseos = () => {

  const {state} = useLocation()

const [precio1,setPrecio1] = useState(state.prop.precioT1)
const [precio2,setPrecio2] = useState(state.prop.precioT2)
const [precio3,setPrecio3] = useState(state.prop.precioT3)
const [precio4,setPrecio4] = useState(state.prop.precioT4)
const [precio5,setPrecio5] = useState(state.prop.precioT5)
const [precio6,setPrecio6] = useState(state.prop.precioT6)
const [precio7,setPrecio7] = useState(state.prop.precioT7)
const [largo1,setLargo1] = useState(state.prop.largoA1)
const [tamaño1,setTamaño1] = useState(state.prop.tamaño1)
const [tamaño2,setTamaño2] = useState(state.prop.tamaño2)
const [tamaño3,setTamaño3] = useState(state.prop.tamaño3)
const [tamaño4,setTamaño4] = useState(state.prop.tamaño4)
const [tamaño5,setTamaño5] = useState(state.prop.tamaño5)
const [tamaño6,setTamaño6] = useState(state.prop.tamaño6)
const [tamaño7,setTamaño7] = useState(state.prop.tamaño7)

  const dispatch = useDispatch ();
  const dispatchCantidad = useDispatch ();
  const [cantidad, setCantidad] = useState (1);
  const [precioSeleccionado, setPrecioSeleccionado] = useState (
    state.prop.precioT1
  );
  const [tamañoSeleccionado, setTamañoSeleccionado] = useState (
    state.prop.tamaño1
  );

  const opcionSeleccioanda = (precio,tamaño) => {
    setPrecioSeleccionado (precio);
    setTamañoSeleccionado (tamaño);
  };

  const sumarCantidad = () => {
    setCantidad (cantidad + 1);

  };
  const restarCantidad = () => {
    setCantidad (cantidad - 1);

    if (cantidad <= 1) {
    
      setCantidad (1);
      toast.error ('No puedes elegir una cantidad negativa o menor a 1');
    }
  };

 


  /* if (alimentoLocal && state.prop.precio4 && state.prop.precio3 && state.prop.precio2 && alimentoLocal[0].precio1) { */
  if (state.prop) {
    const agregarAlCarrito = (cantidad, precio) => {
      let producto = {
        id:state.prop.id,
        nombre :  state.prop.nombre,
        foto: state.prop.imagen,
       descripcion: state.prop.descripcion,
       precio: precioSeleccionado * cantidad,
       cantidad: cantidad,
      marca: state.prop.marca,
      tamaño: tamañoSeleccionado
      }
      
   
      dispatch({ type: "ADD", payload: producto })
     
     /*  dispatch (agregarProductosAlCarrito (producto));
      dispatchCantidad(agregarCantidad())
       */
      toast.success ('Agregado exitosamente');
    };
    return (
      <>
      <HeaderBanner />
    <div className='header'>
  
    <div className='container'>
  
      <Header />
          
    </div>
          <NavBar />
      </div>
  
        <div className="col-lg-12">

          <div className="container">

            <div className="row d-flex">
              <div className="col-lg-12">
                <Link to={'/productos-de-Gatos/paseos'} className="volver-btn">
                  <i class="fa-solid fa-chevron-left  mt-3" />Volver{' '}
                </Link>

              </div>

              <div className="col-lg-5">

                <img
                  className="img-producto-detallee"
                  src={`https://d20fcseidpdftl.cloudfront.net/paseos/${state.prop.imagen}`}
                  alt={state.prop.id}
                />
              </div>

              <div className="col-lg-7 ">

                <div className="col-lg-12">

                  <h3 className="titulo-producto-detalle">
                    {state.prop.nombre}
                  </h3>
                  <div className="col-lg-10 descripcion-producto-detalle">

                    <p>{state.prop.descripcion}</p>
                    <div className="row justify-content-center medidas">
             
                      
             <div className='col-4 col-lg-4'>
             <strong><p className=''>{state.prop.tamaño1}</p></strong>
             <p className=''>{state.prop.largoA1}</p>
             <p className=''>{state.prop.anchob1}</p>
            </div>
        
       



               <div className='col-4 col-lg-4'>
             <strong><p>{state.prop.tamaño2}</p></strong>
             <p >{state.prop.largoA2}</p>
             <p >{state.prop.anchob2}</p>
          </div>
   </div>

   <div className="row justify-content-center medidas">

          <div className='col-4 col-lg-4'>
             <strong><p >{state.prop.tamaño3}</p></strong>
             <p>{state.prop.largoA3}</p>
             <p >{state.prop.anchob3}</p>
             </div>


             <div className='col-4 col-lg-4'>
             <strong><p >{state.prop.tamaño4}</p></strong> 
             <p >{state.prop.largoA4}</p>
             <p >{state.prop.anchob4}</p>
             </div>
   </div>
   
   <div className="row justify-content-center medidas">
   <div className='col-4 col-lg-4'>

             <strong><p>{state.prop.tamaño5}</p></strong>
            <p >{state.prop.largoA5}</p>
             <p >{state.prop.anchob5}</p>
   </div>


             <div className='col-4 col-lg-4'>
             <strong><p >{state.prop.tamaño6}</p></strong>
             <p >{state.prop.largoA6}</p>
             <p >{state.prop.anchob6}</p>
             </div>
   </div>
       
   <div className="row justify-content-center medidas">

             <div className='col-4 col-lg-4'>
       
             <strong><p >{state.prop.tamaño7}</p></strong>
             <p >{state.prop.largoA7}</p>
             <p >{state.prop.anchob7}</p>
       
             </div>
   </div>
 
        
                  </div>
                  <div className='row'>
                  

                  <div className="col-10 col-lg-8 consulta-mensaje">
                    <div className='col-12 col-lg-12'>
                    <p>CONSULTAR STOCK POR WHATSAPP ANTES DE COMPRAR</p>

                    </div>
                    <div className='col-lg-12'>

                    <i className="fa-brands fa-whatsapp"></i>
                    </div>
                  </div>
                  </div>
                 
                </div>

                <div className="row">
                  {
                    precio1 ?(
                  <div
                    className="col-3 col-lg-2 container-opcion-tamaño"
                    aria-checked={true}
                    role="option"
                    aria-selected
                    onClick={() => opcionSeleccioanda (precio1, tamaño1)}
                  >
                    <p>{state.prop.tamaño1}</p>
                    <p>${precio1}</p>

                  </div>

                    ):(
                      <></>
                    )
                  }
                    {
                      precio2 ? (
                        <div
                          className="col-3 col-lg-2 container-opcion-tamaño"
                          aria-checked={true}
                          role="option"
                          aria-selected
                          onClick={() => opcionSeleccioanda (precio2, tamaño2)}
                        >
                          <p>{state.prop.tamaño2}</p>
                          <p>${precio2}</p>
      
                        </div>
                      ):(
                        <></>
                      )
                    }
                    {
                      precio3 ? (
                        <div
                        className="col-3 col-lg-2 container-opcion-tamaño"
                        aria-checked={true}
                        role="option"
                        aria-selected
                        onClick={() => opcionSeleccioanda (precio3, tamaño3)}
                      >
                        <p>{state.prop.tamaño3}</p>
                        <p>${precio3}</p>
    
                      </div>
                      ):(
                        <></>
                      )
                    }
                    {
                      precio4 ? (
                        <div
                    className="col-3 col-lg-2 container-opcion-tamaño"
                    aria-checked={true}
                    role="option"
                    aria-selected
                    onClick={() => opcionSeleccioanda (precio4, tamaño4)}
                  >
                    <p>{state.prop.tamaño4}</p>
                    <p>${precio4}</p>
                  </div>
                      ):(
                        <></>
                      )
                    }
                    {
                      precio5 ? (
                        <div
                    className="col-3 col-lg-2 container-opcion-tamaño"
                    aria-checked={true}
                    role="option"
                    aria-selected
                    onClick={() => opcionSeleccioanda (precio5, tamaño5)}
                  >
                    <p>{state.prop.tamaño5}</p>
                    <p>${precio5}</p>
                  </div>
                      ):(
                        <></>
                      )
                    }
                    {
                      precio6 ? (
                        <div
                    className="col-3 col-lg-2 container-opcion-tamaño"
                    aria-checked={true}
                    role="option"
                    aria-selected
                    onClick={() => opcionSeleccioanda (precio6, tamaño6)}
                  >
                    <p>{state.prop.tamaño6}</p>
                    <p>${precio6}</p>
                  </div>
                      ):(
                        <></>
                      )
                    }
                    {
                      precio7 ? (
                        <div
                    className="col-3 col-lg-2 container-opcion-tamaño"
                    aria-checked={true}
                    role="option"
                    aria-selected
                    onClick={() => opcionSeleccioanda (precio7, tamaño7)}
                  >
                    <p>{state.prop.tamaño7}</p>
                    <p>${precio7}</p>
                  </div>
                      ):(
                        <></>
                      )
                    }
                </div>
                <div className="row botones-cantidades-prod mt-5">
                  <button onClick={restarCantidad} className="col-2 col-lg-1  btn-mas">
                    -
                  </button>
                  <span className="col-2 col-lg-1">
                    <p className="col-2 text-center cantidad-prod">{cantidad}</p>

                  </span>
                  <button onClick={sumarCantidad} className="col-2 col-lg-1 btn-mas ">
                    +
                  </button>
                </div>

                <div className="mt-3">

                  <h4 className="precio">${precioSeleccionado * cantidad}</h4>
                </div>
                <div>
                 
                  <button
                    className="btn-agregar-carrito mt-5"
                    onClick={() => agregarAlCarrito (cantidad, precioSeleccionado)}
                  >
                    Agregar al carrito

                  </button>
                </div>
              </div>

            </div>

          </div>
          <Toaster />
          <div className="row">

            <Footer />

          </div>
        </div>

      </>
    );
  }
 
};

export default VistaDetalleDelProductoDeGatoPaseos;
