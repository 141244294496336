import Header from "../../../header/header"
import HeaderBanner from "../../../header-banner/header-banner"
import Footer from "../../../footer/footer"
import NavBar from "../../../navbar/navbar"
import { Link } from "react-router-dom"

const AccesoriosReptiles = () => {
  return (
    <>
    <HeaderBanner />
  <div className='header'>

  <div className='container'>

    <Header />
         
        


        
   
 
  </div>
        <NavBar />
    </div>
      <div className="container">
      <div className="col-lg-2 slide-left">

      <Link to={'/'} className="volver-btn">
        <i className="fa-solid fa-chevron-left mt-3" />Volver
      </Link>
      </div>
        <h1 style={{marginTop:"2em", marginBottom:"15em"}}>No hay productos aún</h1>
      </div>
      <div className="row">
        <Footer/>
      </div>

    </>
  )
}

export default AccesoriosReptiles