import {Swiper, SwiperSlide} from 'swiper/react';
import './marcas.css';
import 'swiper/css';

import "swiper/css/autoplay";

import {Autoplay, Pagination } from "swiper";




const SliderMarcas = () => {

  return (
    <>
  <h2 className='titulo-marcass'>Nuestras marcas</h2>
  <Swiper         



style={{width:"90%"}} modules={[Autoplay /* Navigation */]}
autoplay={true}
          className="mySwiper">
        <SwiperSlide><img src={require("../imagenes/m1.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/m2.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/m3.png")} alt="marcas"/></SwiperSlide>

      </Swiper>
    
        </>
  );
};

export default SliderMarcas;
