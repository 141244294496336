import {Link} from 'react-router-dom';

import './racion.css';

const PaseoGato = ({prop}) => {

  return (
<div className="col-4 col-lg-2 container-producto mt-4">
  <Link className="link-nombre-producto" to={`/productos-de-Gatos/paseos/${prop.id}`} state={{prop}}>
  <div className="container-imagen-producto">  
      <img
        className="img-producto-perros"
        src={`https://d20fcseidpdftl.cloudfront.net/paseos/${prop.imagen}`}

        alt={prop.id}
      />
      <hr></hr>
  </div>


      {prop.nombre}
    </Link>

</div>
);
};



export default PaseoGato;
