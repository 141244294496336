import './servicios.css';
import data from '../../data-productos/data-servicios';
import { Link } from 'react-router-dom';

/* import { Link } from 'react-router-dom'; */
const Servicios = () => {
  return (
    <div className="container s">
      <div className="row justify-content-center">

        {data.map (p => (
          <div className="col-6 col-lg-5"key={p.id}>
            <Link to={p.link}>
            <div className="item-p" >
              <img
                className="imgs"
                src={require (`../imagenes/${p.url}`)}
                key={p.id}
                alt={p.id}
                />
  
            </div>
                </Link>
          </div>
        ))}

      </div>
    </div>
  );
};

export default Servicios;
