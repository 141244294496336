
import Blog from './blog/blog';
import Footer from './footer/footer';
import Header from './header/header';

import '../index.css';

import Promos from './promos/promos';
import Servicios from './servicios/servicios';
import Carrousel from './slider/slider';
import OtrosAnimales from './otros-animales/otros-animales';
import Slider from './slider-top/slider';
import NavBar from './navbar/navbar';

import HeaderBanner from "./header-banner/header-banner"


import SliderMarcas from './marcas/slider';
import PortadaMarca from './portadademarca/slider';
import Promociones from './promociones/promociones';
const Container = () => {


  return (
    <div>
      <HeaderBanner/>
      <div className='header'>

      <div className="container">
        <Header />


      </div>
      </div>
        <div className="row">
        <div className="container">

          <NavBar />

        </div>
        </div>
      <Slider />

      <div className="container-fluid logos-marcas">
    
        <SliderMarcas/>
          
     
      </div>

      <div className="container-fluid mt-3">


        <OtrosAnimales />

      </div>
   
      <div className="row justify-content-center mt-5">
        <Carrousel />
    

        <PortadaMarca/>
        <div className='row promociones-seccion'>

        <Promociones/>
        </div>

        <div className="container">
          <div className="">
            <h1 className='titulo-servicios-index'>Nuestros servicios</h1>
          </div>
          <Servicios />

        </div>

      </div>
      <div>

        <Blog />

      </div>

      <div className='promos'>

        <Promos />
      </div>
      <div className="row mt-5">

        <Footer />
   
      </div>

    </div>
  );
};

export default Container;
