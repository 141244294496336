import {Swiper, SwiperSlide} from 'swiper/react';

import './marcas.css';
import 'swiper/css';

import 'swiper/css/autoplay'; 




// import required modules
import {Autoplay } from "swiper";

const PortadaMarca = () => {

  return (
    <>
      <Swiper  modules={[Autoplay]}autoplay={true} className="mySwiper portada-marca">
        <SwiperSlide><img src={require("../imagenes/portada1.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/portada2.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/portada3.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/portada4.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/portada5.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/portada6.png")} alt="marcas"/></SwiperSlide>
        <SwiperSlide><img src={require("../imagenes/portada7.png")} alt="marcas"/></SwiperSlide>
 

      </Swiper>
    
        </>
  );
};

export default PortadaMarca;
