import data from '../../../data-blog/articulos.json';
import Header from '../../header/header';
import HeaderBanner from '../../header-banner/header-banner';
import NavBar from "../../navbar/navbar"
import './articulos.css';
import {Link} from 'react-router-dom';
import Footer from '../../footer/footer';

const Articulos = () => {
  return (
    <>
    <HeaderBanner />
  <div className='header'>

  <div className='container'>

    <Header />
         
        


        
   
 
  </div>
        <NavBar />
    </div>
      <div className="container">

        <Link to={'/'} className="volver-btn"><i class="fa-solid fa-chevron-left mt-3"></i>Volver</Link>
      </div>
      <div className="container">
        <div className="row">

          {data.map (a => (
            <div className="articulo-vista col-lg-4 mt-5" key={a.id}>
              <span><p className='titulo-articulo'>{a.titulo}</p></span>
              <div>
                <img className='img-articulos' src={require (`../../imagenes/${a.imagen}`)} alt={a.id} />
              </div>
              <Link to={`/blog/${a.id}`} state={a}><img  className='btn-blog-a' src={require("../../imagenes/btnBlog.png")} alt="boton blog"/></Link>
            </div>
          ))}
        </div>
      </div>

      <div>
   

      </div>
      <div className='row mt-5'>

            <Footer/>
      </div>
   
          </>
         
  );
};

export default Articulos;
