import "./slider.css"


const Slider = () => {
  return (
    <div className='img-portada'>
      
    </div>
  )
}

export default Slider